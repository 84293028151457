import { Flex } from '@chakra-ui/react'
import React from 'react'

import flowerBg from './flower/pattern_BG.png'
// import bonne_bg from './bonne-chance/start_bg.png'
// import plainlaw_bg from './plainlaw/bg.png'

// const background = {
//   flower: flowerBg,
//   // "bonne-chance": bonne_bg,
//   // plainlaw: plainlaw_bg,
// }

const FlowerBg = ({ path, ...props }) => (
  <Flex
    flexDirection="column"
    mt="0"
    height="100%"
    overflow="auto"
    backgroundImage={`url(${flowerBg})`}
    flex="1"
    // backgroundSize={`100% auto`}
    // backgroundPosition={`50% 0%`}
    {...props}
  />
)

export default FlowerBg
