import React from 'react'

import Container from '../../components/Container'
import flowerBg from './flower/start_bg.png'
// import bonne_bg from './bonne-chance/start_bg.png'
// import plainlaw_bg from './plainlaw/bg.png'

const background = {
  'xiahai-flower': flowerBg,
  // "bonne-chance": bonne_bg,
  // plainlaw: plainlaw_bg,
}

const JustfontBg = ({ path, ...props }) => (
  <Container
    backgroundImage={`url(${background[path]})`}
    backgroundSize={`cover`}
    backgroundPosition={`50% 100%`}
    maxWidth={480}
    {...props}
  />
)

export default JustfontBg
