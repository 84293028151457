import { AspectRatio } from '@chakra-ui/react'
import React, { useEffect, useMemo, useRef } from 'react'
import loadable from '@loadable/component'
import { graphql, useStaticQuery } from 'gatsby'

import lottieData from './lottie.json'

const Lottie = loadable.lib(() => import('lottie-web'))

const LottieAnia = ({ lottie }) => {
  const ref = useRef()
  const { allFile: { nodes: images } } = useStaticQuery(graphql`
    query {
      allFile(filter: {relativeDirectory: {eq: "xiahai-flower/lottie"}}) {
        nodes {
          name
          publicURL
          ext
        }
      }
    }
  `)
  const withGatsbyAssets = useMemo(() => {
    const imagesU = images.reduce((obj, img) => {
      const filename = img.name + img.ext
      obj[filename] = img.publicURL.replace(filename, '')
      return obj
    }, {})
    return {
      ...lottieData,
      assets: lottieData.assets.map(a => ({
        ...a,
        u: imagesU[a.p],
      }))
    }
  }, [images])
  useEffect(() => {
    if (!ref.current.children.length) {
      setTimeout(() => {
        lottie.loadAnimation({
          container: ref.current, // the dom element
          renderer: 'canvas',
          loop: true,
          autoplay: true,
          animationData: withGatsbyAssets, // the animation data
          // onComplete: console.log
        });
      })
    }
  }, [withGatsbyAssets])
  return (
    <div ref={ref} />
  )
}

const LogoAnima = () => {
  return (
    <AspectRatio ratio={1}>
      <Lottie>
        {({ default: lottie }) => (
          <LottieAnia lottie={lottie} />
        )}
      </Lottie>
    </AspectRatio>
  )
}

export default LogoAnima
