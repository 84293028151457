import React from 'react'
import { Link } from 'gatsby';

import Box from '../../components/Box';
import Flex from '../../components/Flex';
import Text from '../../components/Text';
// import Button from '../../components/Button';

// import bannerSvg from './banner.svg'
// import trashmobile from './trash-mobile.png'
// import trashmobile2 from './trash-mobile_2.png'
import { responsive } from '../../components/ThemeProvider/theme';
// import withLoading from '../withLoading';
import FlowerBg from './FlowerBg'
import JustfontBg from './JustfontBg'
import LogoAnima from '../../quizes/xiahai-flower/LogoAnima';

const title = {
  'xiahai-flower': <LogoAnima />,
}

const start = {
  // flower: <StaticImage layout="fullWidth" placeholder="blurred" alt="開始挑戰" src="./flower/button.png" />,
}

const DraggerGame = ({ location, data }) => {
  const path = /\/q\/([^/]+)/g.exec(location.pathname)?.[1]
  // console.log(path)
  return (
    <FlowerBg>
      <JustfontBg
        path={path}
        flex="1"
        w="full"
        px={responsive('1em', '2em')}
        as={Flex}
        flexDirection={`column`}
        justifyContent={`center`}
      >
        <Box w={`full`}>
          {data.title === "img" ? (
            title[path]
          ) : <Text fontSize={responsive('1em', '1.5em')} textAlign="center">{data.title}</Text>}
        </Box>
        <Text.NotoSerif fontSize={'1.25em'} letterSpacing={'0.125rem'} textAlign={'center'}>
          臺灣特有種花
          <Text.Inline ml="0.25rem" color="flower.text" fontSize="1.5rem">愛情觀測驗</Text.Inline>
        </Text.NotoSerif>
        <Box textAlign="center" my="2.25em" px="15%" w={`full`}>
          <Link to={`${location.pathname}play/`}>
            <Box
              fontSize={responsive('1.25em', '1.5em')}
              fontFamily="Noto Serif TC"
              bg="flower.buttonBg"
              fontWeight={700}
              letterSpacing={'0.25rem'}
              color="white"
              width="full"
              borderRadius="full"
              py="0.875rem"
              height="auto"
            >開始測驗</Box>
          </Link>
        </Box>
      </JustfontBg>
    </FlowerBg>
  )
}

export default DraggerGame
